import React, { useState } from "react"
import css from "@styled-system/css"
import { AnimatePresence, motion } from "framer-motion"

import { mdiClose } from "@mdi/js"

import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  FadeText,
  Flex,
  H1,
  H6,
  Icon,
  Text,
} from "components"

import mouseCursor from "../../../static/images/mouseCursor.png"

const mqIphoneX = `@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)`

function HeroSection({
  buttonText,
  title,
  subtitle,
  image,
  video,
  panorama = true,
  logoImage,
  useImage
}) {
  return (
    <Flex
      as="section"
      position="relative"
      flexDirection="column"
      justifyContent={["center", "center"]}
      height={["100vh"]}
      pb={[5, 0]}
      css={css({
        background:
          useImage &&
          "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(" +
          image +
          ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        [mqIphoneX]: {
          height: "calc(100vh + 63px)",
        },
        "@media screen and (max-width: 1280px)": {
          pointerEvents: "none",
        },
        "@media (hover: none)": {
          pointerEvents: "none",
        },
      })}

    >
      <Box css={css({
        background:
          "url(" +
          logoImage +
          ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: ["relative", "relative", "relative", "absolute"],
        width: ["100px", "150px"],
        height: ["134px", "204px"],
        zIndex: 1,
        right: [null, null, null, "160px"],
        bottom: [null, null, null, "160px"],
        top: ["0px", "36px", "36px", "unset"],
        left: ["36px", "36px", "72px", "unset"]
      })}>
      </Box>
      {!useImage && video && (
        <div
          css={css({
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          })}
        >
          <video
            width="400"
            autoplay="autoplay"
            loop="loop"
            muted
            defaultMuted
            playsInline
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            })}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div
            css={css({
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))",
            })}
          />
        </div>
      )}


      <AnimatePresence>
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.7,
              },
            },
            exit: {
              opacity: 0,
              transition: { duration: 0.7 },
            },
          }}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <Container minHeight={["33%", 0]} pt={[60, 120]}>
            <Column left>
              <H1 as="h1" color="textReverse">
                <DunkText
                  lines={title ? title.split("\n") : []}
                  animated={true}
                />
              </H1>
              <FadeText delay={1}>
                <Text
                  children={subtitle}
                  mt={[3, 4]}
                  fontFamily="condensed"
                  fontSize={[5, 6]}
                  color="textReverse"
                />
                {buttonText && panorama && (
                  <Box
                    mt={[3, 4]}
                    css={{
                      display: "block",
                      "@media screen and (min-width: 1280px)": {
                        display: "none",
                      },
                      "@media (hover: none)": {
                        display: "block",
                      },
                    }}
                  >
                    <Button
                      children={buttonText}
                      as={"a"}
                      href={panorama}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={{ pointerEvents: "all" }}
                      onClick={event => {
                        event.stopPropagation()
                      }}
                    />
                  </Box>
                )}
              </FadeText>
            </Column>
          </Container>
        </motion.div>
      </AnimatePresence>
    </Flex>
  )
}

export default HeroSection
